<template>
  <div :class="info.type">
    <header>
      <ul>
        <li class="logo">
          <img src="../assets/images/SmartOM-snow.svg"
               class="img-fluid"
               width="180">
        </li>
      </ul>
    </header>
    <main>
      <div class="page-active">
        <div class="card">
          <div class="header">
            <h1>
              <div class="row clearfix">
                <div class="sweet mr-4">
                  <div class="sa-icon sa-success animate"
                       v-if="info.isIcon">
                    <span class="sa-line sa-tip animateSuccessTip"></span>
                    <span class="sa-line sa-long animateSuccessLong"></span>
                    <div class="sa-placeholder"></div>
                    <div class="sa-fix"></div>
                  </div>
                  <div class="sa-icon sa-error animateErrorIcon"
                       v-if="!info.isIcon">
                    <span class="sa-x-mark animateXMark">
                      <span class="sa-line sa-left"></span>
                      <span class="sa-line sa-right"></span>
                    </span>
                  </div>
                </div>
                <div><span v-if="info.loadingAcitve"><i class="fad fa-spinner fa-spin mr-4"></i></span>{{info.message}}</div>
              </div>
            </h1>
            <p>{{info.information}}</p>
          </div>
          <div class="body">
            <div class="mt-4">
              <a v-if="info.type=='success'"
                 :href="info.href"
                 class="btn btn-stealth btn-xl btn-round"><span>{{info.hrefMsg}}</span></a>
              <!-- <a v-if="info.type==fail" :href="info.href" @click="sendActiveMail" class="btn btn-stealth btn-xl btn-round"><span>{{info.hrefMsg}}</span></a> -->
            </div>
          </div>
        </div>
        <div class="MrKangroo">
          <div class="MrKangaroo">
            <img v-if="info.isSrc"
                 src="../assets/images/active/active-success.svg"
                 class="img-fluid">
            <img v-if="!info.isSrc"
                 src="../assets/images/active/active-fail.svg"
                 class="img-fluid">
          </div>
        </div>
      </div>
    </main>
  </div>

</template>

<script>
import { getCookie } from "../utils/cookie"
import {getTitle} from '../utils/locale'
export default {
  name: "active",
  data () {
    return {
      lang:null,
      info: {
        loadingAcitve: true,
        type: "success",
        message: this.$t('active.activating'),
        information: this.$t('active.activatingAccount'),
        src: "../assets/images/active/active-success.svg",
        href: "javascript:void(0)",
        hrefMsg: this.$t('active.pleaseWait'),
      },
      activeForm: {
        activeKey: '',
      }
    }
  },
  created () {
    var locale='ja'
    this.lang = this.getUrlKey("lang",window.location.href)
    if(this.lang != null){
       locale = this.lang;
    }else{
       locale = getCookie("locale");
    }
    // if(typeof(locale)=="undefined"||locale==""){
    //   locale=defaultLocale;
    // }
    this.$i18n.locale = locale;
    this.$validator.locale = locale;
    document.title=getTitle(locale,this.$router.currentRoute.meta.title);

    this.info = {
      loadingAcitve: true,
      type: "success",
      message: this.$t('active.activating'),//"激活中...",
      information: this.$t('active.activatingAccount'),//"正在为您激活账号",
      src: "../assets/images/active/active-success.svg",
      href: "javascript:void(0)",
      hrefMsg: this.$t('active.pleaseWait'),//"请稍后...",
    };
  },
  mounted () {
    let key = this.$route.query.key;
    this.activeForm.activeKey = key;
    this.active();

  },
  methods: {
    async active () {
      const { data: res } = await this.$http.post('/service-user/user/activeUser?activeKey=' + this.activeForm.activeKey, this.activeForm)
      if (res.status == 200) {
        this.info = {
          loadingAcitve: false,
          type: "success",
          message: this.$t('active.activationSuccess'),//"激活成功",
          information: this.$t('active.successAccountInfo'),//"恭喜您，您已成功激活SmartOM的账户。",
          isSrc: true,
          isIcon: true,
          href: "login",
          hrefMsg: this.$t('active.loginNow'),//"即刻登录SmartOM",
        }

      }
      else {
        this.info = {
          loadingAcitve: false,
          type: "fail",
          message: this.$t('active.activationFailed'),//"激活失败",
          information: this.$t('interfaceMsg.' + res.status),//res.status,
          isSrc: false,
          isIcon: false,
          href: "javascript:void(0)",
          hrefMsg: "",
        }
        //  this.message=res.msg
        //  this.$message.error(res.msg)
      }
    },
    getUrlKey(name,url){
　　   return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(url) || [, ""])[1].replace(/\+/g, '%20')) || null
    }



  }



}
</script>

<style>
header {
  position: fixed;
  width: 100%;
  height: 80px;
  display: flex;
  padding: 0 15px;
  justify-content: space-between;
  align-items: center;
  color: var(color-white);
  z-index: 2;
}

.success header {
  background: linear-gradient(45deg, #64afc0 0, #35d8b3 100%);
}

.fail header {
  background: linear-gradient(45deg, #e4989a 0, #ffb4a1 100%);
}

header ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

header li {
  height: 50px;
  padding: 1.165rem 0 0 1.165rem;
}

main {
  padding: 0;
}

.page-active .btn-xl {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  font-size: 1.875em;
  min-width: 13.75rem !important;
  padding: 0.9375rem;
  border: none;
}

.success .page-active .btn:hover,
.success .page-active .btn:focus {
  -moz-box-shadow: 0 0 18px 0 #8bcec9;
  -webkit-box-shadow: 0 0 18px 0 #8bcec9;
  box-shadow: 0 0 18px 0 #8bcec9;
  border: none;
}

.success .page-active .btn-stealth {
  opacity: 0.85;
  background: linear-gradient(45deg, #59c4bc, #637aae);
  -moz-box-shadow: 0 0 18px 0 #8bcec9;
  -webkit-box-shadow: 0 0 18px 0 #8bcec9;
  box-shadow: 0 0 18px 0 #8bcec9;
}

.fail .page-active .btn:hover,
.fail .page-active .btn:focus {
  -moz-box-shadow: 0 0 18px 0 #ffd4cc;
  -webkit-box-shadow: 0 0 18px 0 #ffd4cc;
  box-shadow: 0 0 18px 0 #ffd4cc;
  border: none;
}

.fail .page-active .btn-stealth {
  opacity: 0.85;
  background: linear-gradient(45deg, #e4989a, #ffb187);
  -moz-box-shadow: 0 0 18px 0 #ffd4cc;
  -webkit-box-shadow: 0 0 18px 0 #ffd4cc;
  box-shadow: 0 0 18px 0 #ffd4cc;
}

.page-active .btn-stealth:hover,
.page-active .btn-stealth:focus {
  opacity: 1;
}
</style>